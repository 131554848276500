<template>
  <div style="width: 100%;height: 100%; overflow: hidden;">
    <div id="mapEchart" class="map_container" ref="main_map"></div>

    <nav class="nav" @click="routerPush"></nav>

    <div class="legend">
      <div class="item">
        <div class="legend_title">服务区</div>
        <div class="item_one"></div>
      </div>
      <!-- <div class="item">
        <div class="legend_title">开发区</div>
        <div class="item_two"></div>
      </div> -->
      <div class="item">
        <div class="legend_title">待服务区</div>
        <div class="item_three"></div>
      </div>
    </div>

    <div class="custom_title">
      <img src="../../assets/logo/mapLogo.png" alt="" style="width: 260px">
    </div>
  </div>
</template>

<script>
let Alldata = [
  {
    name: '重庆',
    coord: [106.504962, 29.533155]
  },
  {
    name: '云南',
    coord: [102.712251, 25.040609]
  },
  {
    name: '陕西',
    coord: [108.948024, 34.263161]
  },
  {
    name: '福建',
    coord: [119.306239, 26.075302]
  },
  {
    name: '河北',
    coord: [114.502461, 38.045474]
  },
  {
    name: '江苏',
    coord: [118.767413, 32.041544]
  },
  {
    name: '贵州',
    coord: [106.713478, 26.578343]
  },
  {
    name: '西藏',
    coord: [91.132212, 29.660361]
  },
  {
    name: '湖南',
    coord: [112.982279, 28.19409]
  },
];
let alldataHeight = 1;
import * as echarts from 'echarts';
import "echarts-gl"; //3D地图插件
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import point from "@/assets/logo/point.png";
export default {
  beforeDestroy() {
    clearInterval(this.timer1);
  },
  data() {
    return {
      myChart: null,
      timer1: null, // 定时器
      options: {
        geo3D: {
          // zlevel: -100,
          show: true,
          type: "map3D",
          map: "", // 地图类型。echarts-gl 中使用的地图类型同 geo 组件相同
          regionHeight: 2,
          shading: "realistic",
          realisticMaterial: {
            // detailTexture: "./1.jpeg",
            roughness: 0.2,
            metalness: 0,
          },
          regions: [{}], //默认高亮区域
          emphasis: {
            label: { show: false },
            itemStyle: {
              color: "transparent",
            },
          },
        },
        series: [
          {
            // zlevel: -100,
            regionHeight: 2,
            type: "map3D",
            map: "geoName", // 地图类型。echarts-gl 中使用的地图类型同 geo 组件相同
            data: "mapData", //这里比较重要：获得过滤后的data，这样点击事件时就能获得这个data的值
            label: {
              show: true, // 是否显示标签。
              color: "#fff", // 地图初始化区域字体颜色
              fontSize: 12,
              // textStyle: {
              //   color: "#fff", // 地图初始化区域字体颜色
              //   fontSize: 12,
              // },
              formatter: (e) => {
                // console.log(e.name);
                return ` ${e.name} `;
              },
            },
            shading: "realistic",
            // realisticMaterial: {
            //   detailTexture: "./4.jpeg",
            //   roughness: 0.2,
            //   metalness: 0,
            // },
            itemStyle: {
              borderWidth: 1.5,
              borderColor: "#5FB9DA",
              color: "transparent",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     textStyle: {
            //       color: "#f8fbfb",
            //       fontSize: 18,
            //       padding: [20, 20],
            //       // backgroundColor: {
            //       //   image: "./2.png",
            //       // },
            //     },
            //   },
            //   itemStyle: {
            //     color: "#18B6FE",
            //   },
            // },
          },
        ],
      },
    };
  },
  async mounted() {
    await this.chartMap();
  },
  methods: {
    // 处理数据-line3d尾部标
    iconData(data) {
      let res = [];

      res = data.map(item => {
        return {
          name: item.name,
          value: item.coord.concat('0')
        };
      });
      console.log(`res :>>`, res);
      return res;
    },


    // 处理数据-line3d用
    converData(data) {
      let res = [];

      res = data.map(item => {
        let obj = {
          fromName: '四川',
          toName: item.name,
          coords: [[104.14317180, 30.6039003, 3], item.coord.concat(alldataHeight)]
        };
        return obj;
      });

      return res;
    },

    // 路由跳转
    routerPush() {
      this.$router.push({ path: '/login' });
    },

    chartMap() {
      // 初始化dom
      this.myChart = echarts.init(document.getElementById("mapEchart"));
      // 初始化map
      this.initMap(this.myChart, "map", "100000");
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    // 初始化
    async initMap(chartDOM, geoName, adcode) {
      // 清除echarts实例
      if (chartDOM) {
        chartDOM.clear();
      }
      // 请求map的json
      const mapData = await this.getMapJSON(adcode, geoName);
      console.log('mapData:', mapData);
      // 图表配置项
      const option = this.getOption(geoName, mapData);
      let developArr = [];
      let arr = ['汉盛源'];
      option.geo3D.regions = mapData.map((v) => {
        // if (developArr.includes(v.name)) {
        //   return {
        //     name: v.name,
        //     itemStyle: {
        //       color: '#5bfffc',
        //       grade: this.selectColor(v.num)?.grade,
        //       value: this.selectColor(v.num)?.value,
        //     },
        //   };
        // } else 
        if (arr.includes(v.name)) {
          return {
            name: v.name,
            itemStyle: {
              color: '#0056e0',
              grade: this.selectColor(v.num)?.grade,
              // value: this.selectColor(v.num)?.value,
            },
          };
        } else {
          return {
            name: v.name,
            itemStyle: {
              color: '#3493f9',
              grade: this.selectColor(v.num)?.grade,
              // value: this.selectColor(v.num)?.value,
            },
          };
        }
      }); // 渲染配置
      this.colorArr = option.geo3D.regions.map((v) => {
        return {
          color: v.itemStyle.color,
          grade: v.itemStyle.grade,
          value: v.itemStyle.value,
        };
      });

      this.removeDuplicateObj(this.colorArr);
      this.colorArr.sort(function (a, b) {
        return b.grade - a.grade; // 返回负值表示b在前，正值表示a在前，等于0则保持原有位置不变
      });
      chartDOM.setOption(option);

      let index = 0;
      // 定时高亮
      // this.timer1 = setInterval(() => {
      //   // console.log(`index
      //   //  :>>`, index
      //   // );
      //   option.geo3D.regions = option.geo3D.regions.map((v, index2) => {
      //     if (v.name === arr[index]) {
      //       return {
      //         name: v.name,
      //         itemStyle: {
      //           color: '#177AEB',
      //           grade: v.itemStyle.grade,
      //           value: v.itemStyle.value,
      //         },
      //         height: 4
      //       };
      //     } else if (arr.includes(v.name)) {
      //       return {
      //         name: v.name,
      //         itemStyle: {
      //           color: '#00d5cc',
      //           grade: this.selectColor(v.num)?.grade,
      //           value: this.selectColor(v.num)?.value,
      //         },
      //       };
      //     }
      //     // else if (developArr.includes(v.name)) {
      //     //   return {
      //     //     name: v.name,
      //     //     itemStyle: {
      //     //       color: '#5bfffc',
      //     //       grade: this.selectColor(v.num)?.grade,
      //     //       value: this.selectColor(v.num)?.value,
      //     //     },
      //     //   };
      //     // }
      //     else {
      //       return {
      //         name: v.name,
      //         itemStyle: {
      //           color: '#004f8a',
      //           grade: this.selectColor(v.num)?.grade,
      //           value: this.selectColor(v.num)?.value,
      //         },
      //       };
      //     }
      //   });
      //   // option.series[0].itemStyle.color = 'transparent';
      //   chartDOM.setOption(option);
      //   index++;
      //   if (index === arr.length) {
      //     index = 0;
      //   }
      // }, 2000);
    },
    removeDuplicateObj(arr) {
      // 缓存用于记录
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].color == arr[j].color) {
            arr.splice(j, 1);
            //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
            j--;
          }
        }
      }
      return arr;
    },
    async getMapJSON(adcode = "100000", geoName) {
      console.log(`process.env :>>`, process.env);
      const res = await axios.get(`${process.env.VUE_APP_PUBLIC_PATH}/map/china.json`);

      echarts.registerMap(geoName, res.data);
      // 过滤json数据
      const mapData = res.data.features.map((item) => {
        // console.log(item.properties.name);
        return {
          value: item.properties,
          name: item.properties.name,
          // num: Number(Math.random().toFixed(1)) * 90 + 10,
          num: 0,
        };
      });

      console.log('mapData:', mapData);
      return mapData;
      // 重新注册地图
    },
    getOption(geoName, mapData) {
      // 图表配置项
      this.options = {
        geo: { // 2D地图坐标系
          show: false, // 不显示地图，用于为动效散点提供2D地图坐标系
          map: geoName,
          roam: false, // 禁用缩放、拖拽
          layoutCenter: ['50%', '52%'], // 地图中心位置
          layoutSize: '90%', // 控制地图尺寸（地图的宽度和高度都会改变）
          aspectScale: 0.78, // 控制地图长宽比（此值越小地图越窄，越大地图越宽）
          zlevel: 1,
        },
        geo3D: {
          zlevel: 0,
          show: true,
          top: 50, // 地图距离容器上方的距离
          boxHeight: 3,
          // roam: true,
          animationDurationUpdate: 0,
          // data: mapData,
          type: "map3D",
          map: geoName, // 地图类型。echarts-gl 中使用的地图类型同 geo 组件相同
          regionHeight: 2,
          shading: "realistic",
          realisticMaterial: {
            // detailTexture: "./1.jpeg",
            roughness: 0.2,
            metalness: 0,
          },
          viewControl: {
            // 设置 alpha 和 beta 的角度
            alpha: 60,  // 水平倾斜角度，0 - 45 度
            beta: 0,    // 垂直倾斜角度，0 - 45 度
            distance: 95, // 地图距离视角的距离
            // autoRotateAfterStill: 2,
            // autoRotate: true,
            // autoRotateSpeed: 360,
            // autoRotateDirection: 'ccw',
            // animation: true,
            // animationDurationUpdate: 1000

            rotateSensitivity: 0,
            zoomSensitivity: 0,
            panMouseButton: 'left',
            rotateMouseButton: 'left'

          },
          itemStyle: {
            borderColor: '#6becf5', // 边界颜色
            borderWidth: 0.8,
          },
          label: {
            show: true, // 是否显示标签。
            color: "#ffffff", // 地图初始化区域字体颜色
            fontSize: 16,
            // fontWeight: 'bolder',
            // textStyle: {
            //   fontWeight: 'bolder'
            // },
            formatter: (e) => {
              // console.log(e.name);
              return ` ${e.name} `;
            },
          },
          regions: [
            {
              // name: '四川省',
              // itemStyle: {
              //   color: "#ff9900",
              //   opacity: 1,
              // },
              // label: {
              //   show: false,
              // },
            },
          ], //默认高亮区域
          emphasis: {
            label: { show: true },
            itemStyle: {
              color: "#79f6f9",
            },
            label: {
              show: true,
              color: '#f8fbfb',
              // padding: [15, 20],
              fontSize: 18,
              formatter: (e) => {
                // console.log(e.name);
                return ` ${e.name} `;
              },
              // textStyle: {
              // color: "#f8fbfb",
              // fontSize: 18,
              // padding: [15, 20],
              // backgroundColor: {
              //   image: "./2.png",
              // },
              // },
            },
          },
        },
        series: [
          {
            zlevel: 400,
            type: 'scatter3D',
            coordinateSystem: 'geo3D', // 指定坐标系为 geo3D
            //  data: [
            //    // 每个数据点由经度、纬度、高度组成
            //    { name: '汉盛源', value: [104.14317180, 30.6039003, 5] },
            //  ],
            data: this.iconData(Alldata),
            symbol: 'circle',
            // symbolSize: [10, 10],
            z: 999,
            itemStyle: {
              color: 'transparent' // 点的颜色
            },
            // position: "top",
            label: {
              distance: -38,
              fontSize: 10,

              show: true,
              position: "bottom",
              padding: [10, 10],
              color: "transparent",
              backgroundColor: {
                image: point
              },
              textStyle: {
                color: "transparent",
                backgroundColor: {
                  image: point
                }
              }
            }
          },
          // {
          //   // zlevel: -100,
          //   type: 'scatter3D',
          //   coordinateSystem: 'geo3D', // 指定坐标系为 geo3D
          //   data: [
          //     // 每个数据点由经度、纬度、高度组成
          //     { name: '汉盛源', value: [104.14317180, 30.6039003, 5] },
          //   ],
          //   symbol: 'circle',
          //   symbolSize: [15, 15],
          //   z: 999,
          //   itemStyle: {
          //     color: 'transparent' // 点的颜色
          //   },
          //   label: {
          //     distance: -90,
          //     formatter(params) {
          //       return ` ${params.name} `;
          //     },

          //     show: true,
          //     position: "top",
          //     padding: [15, 25],
          //     color: "#cb0060",
          //     fontSize: 16,
          //     // fontWeight:
          //     fontWeight: 'bolder'
          //     // textStyle: {
          //     //   color: "#cb0060",
          //     //   fontSize: 16,
          //     //   // fontWeight:
          //     //   fontWeight: 'bolder'
          //     // }
          //   }
          // },
          //画线
          // 线 和 点
          {
            type: 'lines3D',
            coordinateSystem: 'geo3D',
            zlevel: 30, //设置这个才会有轨迹线的小尾巴
            polyline: false,
            effect: {
              show: true,
              // period: 2,
              trailLength: 0.2,
              // trailColor: 'red',
              color: '#fff', //流动点颜色
              symbol: 'arrow',
              symbolSize: 6,
              constantSpeed: 5
            },
            lineStyle: {
              color: '#9ae5fc',
              width: 1,
              opacity: 0.5,
              // normal: {
              //   color: '#e4ff00', //线条颜色
              //   width: 2,
              //   curveness: 0.5,
              //   shadowColor: '#fff',
              // }
            },
            blendMode: 'lighter',
            data: this.converData(Alldata),

          },
          // {
          //   name: 'scatter3D',
          //   type: "scatter3D",
          //   coordinateSystem: 'geo3D',
          //   symbol: 'pin',
          //   symbolSize: 30,
          //   opacity: 1,
          //   label: {
          //     show: false,
          //     formatter: ''
          //   },
          //   itemStyle: {
          //     borderWidth: 0.5,
          //     borderColor: '#fff'//气泡边的颜色
          //   },
          //   data: this.iconData(Alldata),
          // },

          { // 动效散点图层，使用2D地图坐标系
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 500,
            rippleEffect: { //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: 'fill', //波纹绘制方式 stroke, fill
              scale: 100 //波纹圆环最大限制，值越大波纹越大
            },
            symbol: 'circle',
            symbolSize: function (val) {
              console.log("val", val);
              return (5 + val[2] * 5) / 15; //圆环大小
            },
            itemStyle: {
              normal: {
                show: true,
                color: '#00d5ff'
              }
            },
            data: [{
              name: '汉盛源',
              value: [104.14317180, 30.6039003, 0.5]
            }],
          },
          // {
          //   zlevel: -10,
          //   regionHeight: 2,
          //   type: "map3D",
          //   map: geoName, // 地图类型。echarts-gl 中使用的地图类型同 geo 组件相同
          //   data: mapData, //这里比较重要：获得过滤后的data，这样点击事件时就能获得这个data的值
          //   // roam: true,

          //   viewControl: {
          //     // 设置 alpha 和 beta 的角度
          //     alpha: 60,  // 水平倾斜角度，0 - 45 度
          //     beta: 0    // 垂直倾斜角度，0 - 45 度
          //   },
          //   label: {
          //     show: true, // 是否显示标签。
          //     textStyle: {
          //       color: "#fff", // 地图初始化区域字体颜色
          //       fontSize: 12,
          //     },
          //     formatter: (e) => {
          //       // console.log(e.name);
          //       return ` ${e.name} `;
          //     },
          //   },
          //   shading: "realistic",
          //   // realisticMaterial: {
          //   //   detailTexture: "./4.jpeg",
          //   //   roughness: 0.2,
          //   //   metalness: 0,
          //   // },

          //   itemStyle: {
          //     borderWidth: 1.5,
          //     borderColor: "#45efff",
          //     color: "transparent",
          //   },


          //   emphasis: {
          //     label: {
          //       show: true,
          //       textStyle: {
          //         color: "#f8fbfb",
          //         fontSize: 18,
          //         padding: [20, 20],
          //         // backgroundColor: {
          //         //   image: "./2.png",
          //         // },
          //       },
          //     },
          //     itemStyle: {
          //       color: "#177AEB",
          //     },
          //   },
          // }
        ],
      };
      return this.options;
    },
    // 图层颜色分布
    selectColor(value) {
      if (value > 0 && value < 20) {
        return { color: "#ff9985", value: "0%-19%", grade: 1 };
      }
      if (value >= 20 && value < 40) {
        return { color: "#f57567", value: "20%-39%", grade: 2 };
      }
      if (value >= 40 && value < 60) {
        return { color: "#e64546", value: "40%-59%", grade: 3 };
      }
      if (value >= 60 && value < 80) {
        return { color: "#b80909", value: "60%-79%", grade: 4 };
      }
      if (value >= 80 && value <= 100) {
        return { color: "#ff102f", value: "80%-100%", grade: 5 };
      }
    },
    // 数据处理
    getBar3DData(mapData) {
      const bar3DData = mapData.features.map((item) => {
        let centroidList = cloneDeep(item.properties.centroid);
        centroidList.push(Number(Math.random().toFixed(1)) * 10 + 10); // [{'经度坐标','纬度坐标','经纬度坐标定位的点的数值'},...]
        return {
          name: item.properties.name,
          value: centroidList,
          code: String(item.properties.adcode), // 地图编码是字符串类型的
        };
      });
      return bar3DData;
    },
  }
};
</script>

<style lang="scss" scoped>
.custom_title {
  font-size: 28px;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  color: #fff;
  font-weight: bold;
}

.legend {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 20px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    .legend_title {
      font-size: 28px;
    }

    .item_one {
      width: 60px;
      height: 20px;
      background-color: #00d5cc;
      margin-left: 20px;
    }

    .item_two {
      width: 60px;
      height: 20px;
      background-color: #5bfffc;
      margin-left: 20px;
    }

    .item_three {
      width: 60px;
      height: 20px;
      background-color: #004f8a;
      margin-left: 20px;
    }

  }
}

.map_container {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/bg/bg5.jpg");
  background-size: 100vw 100vh;
}

.nav {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 50px;
  width: 260px;
  height: 90px;
  z-index: 9999;
  color: #ccc;
  background: url("@/assets/bg/btn_bg_2.png") no-repeat;
  background-size: 260px;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 63px;
  font-size: 28px;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}
</style>